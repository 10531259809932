import '@natscale/react-calendar/dist/main.css';

import { message } from 'antd';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import utils from './helpers/utils';
import { setPageTitle } from './hooks/UsePageViews';
import SentryStart from './start/SentryStart';
import Loader from './components/Loader';
import { canLoadHubspot } from './hooks/useHS';

const ErrorBoundary = React.lazy(() => import('./components/error/ErrorBoundary'));
const APIDown = React.lazy(() => import('./components/error/APIDown'));

const initApp = (attempt = 0) => {
	if (attempt >= 5) return message.error('Failed to load dashboard, please try again later.');
	else if (attempt !== 0) message.loading('Attempting reload...', 8);

	const rootElement: HTMLElement | null = document.getElementById('root');
	if (!rootElement) {
		throw new Error('Root element not found');
	}
	const root = createRoot(rootElement);

	// Due to shorty links & cors, if user is on shorty link && /wallet/:uid/xyz is in the path then redirect to http://:uid>.w.alpineiq.com/
	if (utils.isShortyDomain()) {
		const redirectURL = utils.getCorsRedirectByURL();
		if (redirectURL) {
			window.location.href = redirectURL;
		}
	}

	utils.auth
		.dashData()
		.then(async (data) => {
			utils.dashData = data;
			document.title = data.fullTitle;
			setPageTitle();

			if (canLoadHubspot()) {
				setTimeout(() => {
					utils.loadScript('hs-script-loader', '//js.hs-scripts.com/8363399.js', true);
				}, 3000);
			}

			rootElement.innerHTML = '';
			root.render(
				<React.Suspense fallback={<Loader fullPage />}>
					<ErrorBoundary
						fullPage
						sendAlert
					>
						<App />
					</ErrorBoundary>
				</React.Suspense>,
			);
		})
		.catch((err) => {
			if (attempt === 0) {
				if (rootElement.innerHTML) rootElement.innerHTML = '';
				root.render(
					<React.Suspense fallback={<Loader fullPage />}>
						<APIDown err={err} />
					</React.Suspense>,
				);
			}
			if (!utils.isLocal()) {
				setTimeout(() => initApp(attempt + 1), 10000);
			}
		});
};

SentryStart();
initApp();
